import { useState, useEffect } from "react";
import { Col, Row, Alert } from "react-bootstrap";

export const Newsletter = ({ onValidated }) => {
  const [email, setEmail] = useState('');
  const [status, setStatus] = useState(null); // State to manage subscription status
  const [message, setMessage] = useState(''); // State to manage subscription message

  useEffect(() => {
    if (status === 'success') clearFields();
  }, [status]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!email || email.indexOf("@") === -1) {
      // Handle invalid email
      return;
    }

    try {
      const response = await fetch('https://api.bonniciwebservices.com.au/subscribe', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });
    
      if (response.status === 200) {
        // Update status and message states for success
        setStatus('success');
        setMessage('Thanks for subscribing');
      } else if (response.status === 400) {
        // Update status and message states for error
        setStatus('error');
        setMessage('Email already subscribed');
      } else {
        // Handle other status codes if needed
        setStatus('error');
        setMessage('Failed to subscribe');
      }
    } catch (error) {
      // Handle any errors that occur during the fetch
      console.error('Error subscribing:', error.message);
    }
  };

  const clearFields = () => {
    setEmail('');
  };

  return (
    <Col lg={12}>
      <div className="newsletter-bx wow slideInUp">
        <Row>
          <Col lg={12} md={6} xl={5}>
            <h3>Subscribe to our Newsletter<br /> & Never miss latest updates</h3>
            {status === 'sending' && <Alert>Sending...</Alert>}
            {status === 'error' && <Alert variant="danger">{message}</Alert>}
            {status === 'success' && <Alert variant="success">{message}</Alert>}
          </Col>
          <Col md={6} xl={7}>
            <form onSubmit={handleSubmit}>
              <div className="new-email-bx">
                <input value={email} type="email" onChange={(e) => setEmail(e.target.value)} placeholder="Email Address" />
                <button type="submit">Submit</button>
              </div>
            </form>
          </Col>
        </Row>
      </div>
    </Col>
  );
};
