import { useState, useEffect } from "react";
import { Navbar, Nav, Container, Modal, Button, Form } from "react-bootstrap";
import logo from '../assets/img/bws-logo.png';
import navIcon1 from '../assets/img/nav-icon1.svg';
import navIcon2 from '../assets/img/nav-icon2.svg';
import navIcon3 from '../assets/img/nav-icon3.svg';
import { HashLink } from 'react-router-hash-link';
import {
  BrowserRouter as Router
} from "react-router-dom";
import './css/customStyles.css';

export const NavBar = () => {
  const [activeLink, setActiveLink] = useState('home');
  const [scrolled, setScrolled] = useState(false);
  const [showModal, setShowModal] = useState(false); // State for competition modal visibility
  const [showTermsModal, setShowTermsModal] = useState(false); // State for terms modal visibility
  const [formData, setFormData] = useState({
    fullName: '',
    businessName: '',
    businessEmail: '',
    businessLocation: '',
    passcode: '',
    hasWebsite: false,
    websiteURL: '',
    acceptedTerms: false
  });

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const onUpdateActiveLink = (value) => {
    setActiveLink(value);
  };

  const handleModalOpen = () => {
    setShowModal(true);
    document.body.style.overflow = 'hidden'; // Prevent body scroll
  };

  const handleModalClose = () => {
    setShowModal(false);
    document.body.style.overflow = 'auto'; // Re-enable body scroll
  };

  const handleTermsModalOpen = (e) => {
    e.preventDefault();
    setShowTermsModal(true);
    document.body.style.overflow = 'hidden'; // Prevent body scroll
  };

  const handleTermsModalClose = () => {
    setShowTermsModal(false);
    document.body.style.overflow = 'auto'; // Re-enable body scroll
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://api.bonniciwebservices.com.au/validate-entry', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData),
      });
      const data = await response.json();
      if (data.success) {
        alert('Entry submitted successfully!');
        handleModalClose();
      } else {
        alert(data.message || 'Validation failed. Please check your details and try again.');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred. Please try again later.');
    }
  };

  return (
    <Router>
      <Navbar expand="md" className={scrolled ? "scrolled" : ""}>
        <Container>
          <Navbar.Brand href="/">
            <img src={logo} alt="Logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav">
            <span className="navbar-toggler-icon"></span>
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link href="#home" className={activeLink === 'home' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('home')}>Home</Nav.Link>
              <Nav.Link href="#skills" className={activeLink === 'skills' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('skills')}>Skills</Nav.Link>
              <Nav.Link href="#projects" className={activeLink === 'projects' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('projects')}>Projects</Nav.Link>
            </Nav>
            <span className="navbar-text">
              <div className="social-icon">
                <a href="https://au.linkedin.com/in/nate-bonnici-9291b8230"><img src={navIcon1} alt="LinkedIn" /></a>
                <a href="https://www.facebook.com/share/fJLU9264BH7fLe42/?mibextid=LQQJ4d"><img src={navIcon2} alt="" /></a>
                <a href="https://www.instagram.com/bonniciwebservices/"><img src={navIcon3} alt="" /></a>
              </div>
              <HashLink to='#connect'>
                <button className="vvd"><span>Let’s Connect</span></button>
              </HashLink>
              <button className="vvd" onClick={handleModalOpen}><span>Free Website Competition Entry</span></button>
            </span>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      {/* Competition Entry Modal */}
      <Modal
        show={showModal}
        onHide={handleModalClose}
        centered
        backdrop="static"
        keyboard={false}
        className="modal-custom"
      >
        <Modal.Header closeButton className="bg-dark text-white">
          <Modal.Title>Free Website Competition Entry</Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-dark text-white modal-body-scrollable">
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="formFullName">
              <Form.Label>Full Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter your full name"
                name="fullName"
                value={formData.fullName}
                onChange={handleInputChange}
                required
                className="bg-dark text-white"
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBusinessName">
              <Form.Label>Business Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter your business name"
                name="businessName"
                value={formData.businessName}
                onChange={handleInputChange}
                required
                className="bg-dark text-white"
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formExisting">
              <Form.Check
                type="checkbox"
                label={<span>Do you have a current Website?</span>}
                name="hasWebsite"
                checked={formData.hasWebsite}
                onChange={handleInputChange}
              />
            </Form.Group>

            {/* Conditionally render the URL field based on checkbox state */}
            {formData.hasWebsite && (
              <Form.Group className="mb-3" controlId="formwebsiteURL">
                <Form.Label>Current Website URL</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your current Website URL"
                  name="websiteURL"
                  value={formData.websiteURL}
                  onChange={handleInputChange}
                  className="bg-dark text-white"
                />
              </Form.Group>
            )}

            <Form.Group className="mb-3" controlId="formBusinessEmail">
              <Form.Label>Business Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter your business email"
                name="businessEmail"
                value={formData.businessEmail}
                onChange={handleInputChange}
                required
                className="bg-dark text-white"
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBusinessLocation">
              <Form.Label>Business Location</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter your business location"
                name="businessLocation"
                value={formData.businessLocation}
                onChange={handleInputChange}
                required
                className="bg-dark text-white"
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formPasscode">
              <Form.Label>Validation Passcode</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter the passcode"
                name="passcode"
                value={formData.passcode}
                onChange={handleInputChange}
                required
                className="bg-dark text-white"
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formTerms">
              <Form.Check
                type="checkbox"
                label={<span>Click <a href="#" onClick={handleTermsModalOpen}>Terms and Conditions</a> to accept</span>}
                name="acceptedTerms"
                checked={formData.acceptedTerms}
                onChange={handleInputChange}
                required
              />
            </Form.Group>

            <Button variant="primary" type="submit">
              Submit Entry
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      {/* Terms and Conditions Modal */}
      <Modal
        show={showTermsModal}
        onHide={handleTermsModalClose}
        centered
        backdrop="static"
        keyboard={false}
        className="modal-custom"
      >
        <Modal.Header closeButton className="bg-dark text-white">
          <Modal.Title>Terms and Conditions</Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-dark text-white modal-body-scrollable">
          <p>
            1. **Eligibility:** This competition is open only to local businesses within the specified region.
            <br/>
            2. **Entry Limit:** Only one entry per business is allowed.
            <br/>
            3. **Competition Period:** Entries will be accepted until 11:59 PM on the 29th of August. The winner will be chosen at random and announced on the 30th of August.
            <br/>
            4. **Prize:** The winner will receive a free website design and development service. Hosting is not included in the prize and is the responsibility of the winner. Hosting can be provided by Bonnici Web Services for $10 per month, which includes support and website modifications.
            <br/>
            5. **Portfolio Use:** By entering this competition, the winner agrees that Bonnici Web Services can showcase the developed website as part of their portfolio.
            <br/>
            6. **Transfer of Rights:** The winner will have full rights to the website upon completion, subject to the conditions mentioned in these terms.
            <br/>
            7. **External Hosting:** If the winner opts for external hosting, a one-time fee of $50 will be charged for setup. Alternatively, the winner may set up hosting independently at no cost, with no further support or alterations from Bonnici Web Services.
            <br/>
            8. **Domain Setup:** If the winner has a domain, Bonnici Web Services will provide the required DNS records to point the domain to the desired host. The preview build will be hosted under a subdomain of bonniciwebservices.com.au until finalization.
          </p>
        </Modal.Body>
      </Modal>
    </Router>
  );
};
